import { useCartContext } from '../../context/CartContext';
// import { useProductsContext } from '../context/ProductsContext';
import { CgShoppingBag } from 'react-icons/cg';
import { createStyles, Indicator } from '@mantine/core';

const useStyle = createStyles({
	root: { cursor: 'pointer' },
	indicator: {
		'fontWeight': 'bolder',
		'bottom': '17px !important',
		'right': '5px !important',
		'fontSize': '12px',
		'border': '2px solid white',
		'.mantine-MachineNumber-baseNumber': { height: 20 },
	},
});

export const Cart = ({ onClick }) => {
	const { classes } = useStyle();

	const { cart } = useCartContext();


	let totalItemInCart = cart.reduce((acc, curr, c) => acc + curr.qty, 0);
	return (
		<Indicator
			onClick={() => onClick(true)}
			styles={{ root: classes.root, indicator: classes.indicator }}
			dot
			label={totalItemInCart}
			size={25}
			position='bottom-end'>
			<CgShoppingBag size={35} />
		</Indicator>
	);
};
