import { useState } from 'react';
import {
	Button,
	Switch,
	TextInput,
	Stack,
	useMantineTheme,
	Group,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { RichTextEditor } from '@mantine/rte';
import { Formik, Form } from 'formik';

import ImageUploader from '../ImageUploader/ImageUploader';
import axios from 'axios';

export const AddNewProductForm = ({ closeModal }) => {
	const [textEditorValue] = useState('');
	const [imagePathFiles, setImagePathFiles] = useState([]);

	const matchViewportSize = useMediaQuery('(max-width: 768px)');

	const theme = useMantineTheme();

	const styles = {
		body: { width: '100%', flexDirection: 'row-reverse' },
		track: { marginLeft: 'auto' },
		label: {
			fontFamily: "Figtree, 'Noto Sans', sands-serif !important",
			cursor: 'pointer',
			userSelect: 'none',
			fontSize: matchViewportSize
				? theme.fontSizes.lg
				: theme.fontSizes.xl,
			fontWeight: 500,
			padding: 0,
		},
	};

	const updateImageFiles = (filesArr) => {
		setImagePathFiles(filesArr);
	};
	return (
		<Formik
			initialValues={{
				title: '',
				desc: textEditorValue,
				price: { retail: 0, sales: 0 },
				shortTitle: '',
				isOutStock: false,
				stockQty: 0,
				productCode: '',
				hasVideo: false,
				videoUrl: '',
				category: '',
			}}
			onSubmit={async (values, { setSubmitting }) => {
				values.desc = textEditorValue;
				const formData = new FormData();
				imagePathFiles.forEach((image, index) => {
					formData.append('files', imagePathFiles[index]);
				});
				formData.append('form', JSON.stringify(values));
				setTimeout(async () => {
					console.log(values);
					const form = await axios.post(
						'/api/v1/products',
						formData,
						{
							headers: { 'Content-Type': 'multipart/form-data' },
						}
					);
					console.log(form);
					closeModal();
					setSubmitting(false);
				}, 400);
			}}>
			{({
				values,
				handleChange,
				handleSubmit,
				isSubmitting,
				setFieldValue,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Stack spacing={15}>
						<Switch
							label='Is product in stock?'
							size={matchViewportSize ? 'sm' : 'md'}
							name='isOutStock'
							onLabel='YES'
							offLabel='NO'
							checked={values.isOutStock}
							onChange={(event) => {
								setFieldValue(
									'isOutStock',
									event.target.checked
								);
							}}
							styles={styles}
						/>
						<ImageUploader
							name='images'
							updateImageFiles={updateImageFiles}
						/>
						<Switch
							labelposition='right'
							label='Has video?'
							size={matchViewportSize ? 'sm' : 'md'}
							name='videoSrc'
							onLabel='YES'
							offLabel='NO'
							value={values.hasVideo}
							checked={values.hasVideo}
							onChange={(event) => {
								setFieldValue('hasVideo', event.target.checked);
							}}
							styles={styles}
						/>

						<TextInput
							name='videoUrl'
							label='Video URL'
							size='lg'
							onChange={handleChange}
							value={values.videoUrl}
							disabled={!values.hasVideo}
						/>
						<TextInput
							name='title'
							label='Title'
							size='lg'
							onChange={handleChange}
							value={values.title}
						/>
						<TextInput
							name='shortTitle'
							label='Short Title'
							size='lg'
							onChange={handleChange}
							value={values.shortTitle}
						/>
						<RichTextEditor
							name='desc'
							label='Description'
							size='lg'
							onChange={(e) => {
								setFieldValue('desc', e);
							}}
							value={values.desc}
							id='rte'
							controls={[
								[
									'bold',
									'italic',
									'underline',
									'link',
									'image',
									'video',
								],
								['unorderedList', 'h1', 'h2', 'h3'],
								['alignLeft', 'alignCenter', 'alignRight'],
							]}
						/>
						<Group>
							<TextInput
								name='price.retail'
								label='Retail Price'
								size='lg'
								onChange={handleChange}
								value={values.price.retail}
							/>
							<TextInput
								name='price.sales'
								label='Sales Price'
								size='lg'
								onChange={handleChange}
								value={values.price.sales}
							/>
						</Group>
						<TextInput
							name='stockQty'
							label='Quantity'
							size='lg'
							onChange={handleChange}
							value={values.stockQty}
						/>
						<TextInput
							name='productCode'
							label='Product Code'
							size='lg'
							onChange={handleChange}
							value={values.productCode}
						/>
						<TextInput
							name='category'
							label='Category'
							size='lg'
							onChange={handleChange}
							value={values.category}
						/>
						<Button
							size='lg'
							type='submit'
							disabled={isSubmitting}>
							Add New Product
						</Button>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default AddNewProductForm;
