// import React, { useContext } from 'react';

// import Context from '../context/context';

import logo from '../assets/images/Xomos1_Logo_Alpha_White.png';
import { Text } from '@mantine/core';

export const Hero = () => {
	return (
		<section className='hero-section container'>
			<div className='hero-img hero-bg'>
				{/* <div
					className='overlay'
					style={{ width: '100%', opacity: 0.4 }}></div> */}
				<div className='hero-content'>
					<img
						className='logo'
						src={logo}
						alt=''
					/>
					<Text>
						Electronics and miscellaneous items Whole Sales.
					</Text>
				</div>
			</div>
		</section>
	);
};
