import { createStyles, Drawer, Group, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useCartContext } from '../../context/CartContext';
import { useProductsContext } from '../../context/ProductsContext';
import { format } from '../../utils/format';
import ShoppingCart from './ShoppingCart';

const useStyle = createStyles({
	links: {
		maxWidth: '100%',
		padding: '15px',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'uppercase',
		letterSpacing: '1.5px',
		fontWeight: 700,
	},
	viewCart: {
		backgroundColor: '#E7E7E7',
		color: '#3f3f3f',
	},
	checkout: {
		backgroundColor: '#141414',
		color: '#e7e7e7',
	},
});

const Subtotal = ({ cart, products }) => (
	<Group
		py={10}
		position='apart'>
		<Text
			weight={900}
			transform='uppercase'>
			SubTotal:
		</Text>
		<Text
			weight={900}
			size='xl'>
			{format(
				cart.reduce((total, cartItem) => {
					const product = products.find(
						(item) => item._id === cartItem._id
					);
					return total + product?.price.sales * cartItem.qty;
				}, 0)
			)}
		</Text>
	</Group>
);

export default function ItemsInCart({ open, setOpen }) {
	const { cart } = useCartContext();
	const { classes, cx } = useStyle();
	const {
		value: { products },
	} = useProductsContext();

	console.log(cart);

	const cartItems = cart.map((cartItem) => (
		<ShoppingCart
			key={cartItem._id}
			{...cartItem}
		/>
	));

	return (
		<Drawer
			lockScroll={false}
			padding='xl'
			title={
				<Text
					weight={900}
					size='xl'>
					Shopping Cart
				</Text>
			}
			withinPortal={false}
			position='right'
			size='md'
			opened={open}
			onClose={() => setOpen(false)}>
			{cart.length > 0 ? (
				<Stack>
					{cartItems}
					<Subtotal
						cart={cart}
						products={products}
					/>
					<Link className={cx(classes.links, classes.viewCart)}>
						View Cart
					</Link>
					<Link className={cx(classes.links, classes.checkout)}>
						Checkout
					</Link>
				</Stack>
			) : (
				<Text color='#999999' weight={400}>No products in the cart.</Text>
			)}
		</Drawer>
	);
}
