import React from 'react';
import { LeftSidebar } from './LeftSidebar';
import Admin from './Admin';
import '../assets/css/style.min.css';
// import 'antd/dist/antd.less';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export default function AdminDashboard({ children }) {
	const navigate = useNavigate();
	return (
		<div
			className='wrapper'
			style={{
				display: 'flex',
			}}>
			<LeftSidebar />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100vh',
					paddingTop: '20px',
				}}>
				<Button
					style={{ maxWidth: '200px', marginLeft: '20px' }}
					uppercase
					onClick={() =>
						navigate('/admin/products/new', {
							state: { openModal: true },
						})
					}>
					{' '}
					Add New Product
				</Button>
				<Admin />
			</div>
		</div>
	);
}
