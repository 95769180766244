import React, { useMemo } from 'react';
import { format } from '../utils/format';

import {
	ActionIcon,
	BackgroundImage,
	Box,
	Card,
	createStyles,
	Group,
	Image,
	Stack,
	Text,
	Tooltip,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Ratings } from './Ratings/Ratings';
import { Link } from 'react-router-dom';
import { useCartContext } from '../context/CartContext';
import { CgShoppingBag } from 'react-icons/cg';

const useStyle = createStyles({
	links: {
		maxWidth: '100%',
		padding: '15px',
		borderRadius: '5px',
		textAlign: 'center',
		textTransform: 'uppercase',
		letterSpacing: '1.5px',
		fontWeight: 700,
		cursor: 'pointer',
	},
	viewCart: {
		backgroundColor: '#E7E7E7',
		color: '#3f3f3f',
	},
	checkout: {
		backgroundColor: '#141414',
		color: '#e7e7e7',
	},
});

export const ProductCard = ({ data }) => {
	const { cart, addToCart } = useCartContext();
	const { classes, cx } = useStyle();

	const cartQty = useMemo(() => {
		return { ...cart.filter((item) => item._id === data._id)[0] };
	}, [cart, data._id]);

	let { title, images } = data;

	return (
		<Link to={`/shop/${data._id}`}>
			<Card
				shadow='sm'
				withBorder
				sx={{
					'.background-image': {
						position: 'relative',
						height: '100%',
						minHeight: '200px',
						maxHeight: '200px',
					},
					'.action-buttons': {
						'height': 200,
						'width': '100%',
						'display': 'flex',
						'justifyContent': 'center',
						'alignItems': 'center',
						'transition': 'opacity .3s ease',
						'opacity': 0,
						'background': '#3232326e',
						'.mantine-ActionIcon-root': {
							background: '#ffffff5c',
							transition: 'transform .3s ease-out',
							transform: 'translateY(15px)',
						},
					},
					'&:hover': {
						'transition': 'opacity 1s ease',
						'.action-buttons': {
							'opacity': 1,
							'.mantine-ActionIcon-root': {
								transform: 'translateY(0)',
							},
						},
					},
				}}>
				<Card.Section>
					<BackgroundImage
						className='background-image'
						src={images[0].originalURL}
						height={200}
						alt={title}>
						<Box className='action-buttons'>
							<Tooltip
								label='Add to Cart'
								color='dark'
								withArrow>
								<ActionIcon
									variant='filled'
									radius='xl'
									size={50}
									onClick={(e) => {
										e.preventDefault();

										setTimeout(() => {
											showNotification({
												onOpen: () => {
													addToCart(
														data._id,
														(cartQty.qty || 0) + 1
													);
												},
												position: 'bottom-right',
												disallowClose: true,
												autoClose: 5000,
												radius: 0,
												message: (
													<Stack>
														<Group>
															<Image
																src={
																	data
																		.images[0]
																		.originalURL
																}
																width={75}
																height={75}
															/>
															<Stack spacing={0}>
																<Text
																	weight={900}
																	size='lg'>
																	{data.title}
																</Text>
																<Text
																	size='lg'
																	weight={500}
																	color='#6b6b6b'
																	span>
																	Has been
																	added to
																	your cart!
																</Text>
															</Stack>
														</Group>
														<Group grow>
															<Box
																component='a'
																className={cx(
																	classes.links,
																	classes.viewCart
																)}>
																View Cart
															</Box>
															<Box
																component='a'
																className={cx(
																	classes.links,
																	classes.checkout
																)}>
																Checkout
															</Box>
														</Group>
													</Stack>
												),
												styles: (theme) => ({
													root: {
														'padding': '15px',
														'&::before': {
															content: 'unset',
														},
													},
													body: { marginRight: 0 },
												}),
											});
										}, 500);
									}}
									styles={{ root: { opacity: 0 } }}>
									<CgShoppingBag size={30} />
								</ActionIcon>
							</Tooltip>
						</Box>
					</BackgroundImage>
				</Card.Section>
				<Group>
					<Stack spacing={0}>
						<Text
							weight={400}
							size='sm'
							color='gray.6'
							mt='xs'>
							{data.category.toUpperCase()}
						</Text>
						<Text
							weight={500}
							size='xl'>
							{data.title}
						</Text>
					</Stack>
				</Group>
				<Ratings rate={3} />
				<Group spacing={8}>
					<Text
						strikethrough
						color='dimmed'
						size={20}
						weight={500}>
						{format(data.price.retail)}
					</Text>
					<Text
						weight={700}
						size={25}>
						{format(data.price.sales)}
					</Text>
				</Group>
			</Card>
		</Link>
	);
};
