import {
	Box,
	createStyles,
	Group,
	Header,
	Container,
	Stack,
	Text,
	Title,
	Burger,
} from '@mantine/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Xomos1_Logo_Alpha.png';
import { Cart } from '../Cart/cart';
import { IconMail, IconPhone } from '@tabler/icons';
import ItemsInCart from '../Cart/ItemsInCart';
import { useCartContext } from '../../context/CartContext';

const HEADER_HEIGHT = 35;

const useStyles = createStyles((theme) => ({
	header: {
		borderBottom: 'none',
	},

	inner: {
		maxWidth: '1440px',
		paddingRight: theme.spacing.xl,
		paddingLeft: theme.spacing.xl,
		[theme.fn.smallerThan('sm')]: { paddingRight: 5, paddingLeft: 5 },
		height: HEADER_HEIGHT,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	orderText: {
		[theme.fn.smallerThan('sm')]: { display: 'none' },
	},
	burger: {
		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
	},
}));

export const AppHeader = () => {
	const { classes } = useStyles();
	const [open, setOpen] = useState(false);

	const { cart } = useCartContext();

	return (
		<>
			<ItemsInCart
				open={open}
				setOpen={setOpen}
				items={cart}
			/>
			<Header
				className={classes.header}
				py={15}>
				<Container className={classes.inner}>
					<Group spacing={0}>
						<Burger className={classes.burger} />
						<Link to='/'>
							<img
								width='100px'
								className='logo'
								src={logo}
								alt=''
							/>
						</Link>
					</Group>

					<Group>
						<Stack
							spacing={0}
							className={classes.orderText}>
							<Title order={6}>For Orders</Title>
							<Group style={{ marginLeft: 'auto' }}>
								<Box
									style={{
										display: 'flex',
										alignItems: 'center',
									}}>
									<IconPhone
										size={25}
										style={{ marginRight: 5 }}
									/>
									<Text
										size={20}
										weight={700}>
										929-346-1388
									</Text>
								</Box>

								<Box
									style={{
										display: 'flex',
										alignItems: 'center',
									}}>
									<IconMail
										size={25}
										style={{ marginRight: 5 }}
									/>
									<Text
										size={20}
										weight={700}>
										orders@xomos1.com
									</Text>
								</Box>
							</Group>
						</Stack>
						<Cart
							onClick={setOpen}
							style={{ cursor: 'pointer' }}
						/>
					</Group>
				</Container>
			</Header>
		</>
	);
};
