import { Group, Text } from '@mantine/core';
import { HiStar } from 'react-icons/hi';

export const Ratings = ({ rate, size, color }) => {
	return (
		<Group spacing={0}>
			{[...Array(5)].map((_, index) =>
				rate > index ? (
					<HiStar
						key={index}
						color={color ? color : '#8a8a8a'}
						size={size}
					/>
				) : (
					<HiStar
						key={index}
						color='#cecece'
						size={size}
					/>
				)
			)}
			<Text
				inline
				size={15}
				style={{ marginLeft: 5 }}>
				(45)
			</Text>
		</Group>
	);
};
