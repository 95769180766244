import { Modal, MediaQuery } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
	/* useLoaderData, */ useLocation,
	useNavigate,
} from 'react-router-dom';
import EditProductForm from './EditProductForm';

const styles = {
	modal: {
		fontFamily: "Figtree, 'Noto Sans', sands-serif",
	},
	title: {
		fontFamily: "Figtree, 'Noto Sans', sands-serif",
		fontWeight: 700,
		fontSize: '2.5rem',
	},
};

function EditModal() {
	const { state /* data */ } = useLocation();
	const [openModal, setOpenModal] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		state.openModal && setOpenModal(true);
	}, [state.openModal]);

	return (
		<MediaQuery
			smallerThan='md'
			styles={{
				'.mantine-Modal-modal': { width: '600px' },
			}}>
			<Modal
				size={1200}
				opened={openModal}
				onClose={() => {
					setOpenModal(false);
					navigate('/admin/products');
				}}
				styles={styles}
				title={`Editing product: ${state.product.title}`}
				closeOnClickOutside={false}>
				<EditProductForm
					product={state.product}
					setOpenModal={(e) => {
						setOpenModal(false);
						// navigate('/admin/products');
					}}
				/>
			</Modal>
		</MediaQuery>
	);
}

export default EditModal;

// export async function loader(params) {
// 	const singleProduct = await getSingleProduct(params);
// 	return singleProduct;
// }
