// import { useState } from 'reat';

const reducer = (state, action) => {
  let { type, payload } = action;

	switch (type) {
    case 'PRODUCT_DATA':
      return { ...state, productsData: payload };
    case 'ADMIN_DATA':
      return { ...state, adminProduct: payload };

    case 'ISLOADING':
      return { ...state, loading: payload };

    case 'DATA_LOADED':
      return { ...state, dataLoaded: payload };

    case 'ITEM_DETAIL':
      return { ...state, productDetail: payload };

    case 'SHOW_SUMMARY':
      return { ...state, isVisible: payload };
    case 'ORDER_SUMMARY':
      return { ...state, orderSummary: payload };
    case 'CONFIRM_MODAL':
      return { ...state, confirmModal: payload };
    // case 'EDIT_ITEM':
    //   return { ...state, itemToEdit: payload };
    case 'ADD_PRICE':
      return { ...state, itemToEdit: { priceList: payload } };
    // case "SET_EDIT_ITEM":
    //   return { ...state, itemToEdit: payload };
    case 'SET_LOCALSTORAGE':
      return {
        ...state,
        setLocalStorage: sessionStorage.setItem(
          'product',
          JSON.stringify(payload)
        ),
      };
    case 'INCREASE':
      return { ...state, qtyCount: payload };
    case 'DECREASE':
      return { ...state, qtyCount: payload };
    case 'QTY_VALUE':
      return { ...state, qtyCount: payload };

    case 'PURCHASE_TOTAL':
      return {
        ...state,
        purchaseTotal: payload,
      };
    case 'ISLOADING_MODAL':
      return {
        ...state,
        showCartModal: !state.showCartModal,
      };
    case 'ISVISIBLE':
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    case 'TIMEOUT_VALUE':
      return {
        ...state,
        timer: payload,
      };
    case 'CONFIRMATION_MODAL':
      return {
        ...state,
        openConfirmationModal: {
          openModal: !state.openConfirmationModal.openModal,
          orderNumber: payload,
        },
      };
    case 'REDIRECT':
      return {
        ...state,
        redirect: !state.redirect,
      };
    case 'CUSTOMERS_DATA':
      return {
        ...state,
        customers: payload,
      };
    case 'HIDE_COMPONENTS':
      return {
        ...state,
        hideComponents: payload,
      };
    case 'SET_CUSTOMER_INFO':
      return {
        ...state,
        buyerInfo: payload,
      };

    default:
      return state;
  }
};

export default reducer;