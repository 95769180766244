import React from 'react'

export const Spinner = ({ isLoading }) => {
    return (
        isLoading && <div className='spinner-container' style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <div className='spinner'>
                <div className='bounce1'></div>
                <div className='bounce2'></div>
                <div className='bounce3'></div>
            </div>
        </div>
    )
}
