import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import 'cleave.js/src/addons/phone-type-formatter.i18n';
import Cleave from 'cleave.js/react';
import Context from '../context/context';
// import axios from "axios";
import { Spinner } from './assets/spinner';

import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
// import { format } from '../App';
// import { ConfirmModal } from './confirmModal';

import { Portal } from './assets/Portal';

import { format } from '../utils/format';
// import { URLSUFIX, format } from '../App';

import { ReactComponent as TrashCan } from '../assets/images/trash_can_icon.svg';
import { ConfirmModal } from './paymentModal';
// import { Dialog } from './assets/dialog';

const personalDetailSchema = Yup.object().shape({
	customerName: Yup.string().required('Required'),
	customerPhone: Yup.number().required('Required'),
	customerEmail: Yup.string().email('Invalid email').required('Required'),
	customerAddress: Yup.string().required('Required'),
	customerReferredBy: Yup.string(),
	customerNote: Yup.string(),
});

export const ProductCheckout = ({ location }) => {
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false);
	// const [orderedProduct, setOrderedProduct] = useState({ buyerInfo: null, itemsPurchased: null });
	const [note, setNote] = useState('');
	// eslint-disable-next-line
	const [disabled, setDisabled] = useState(true);

	// const [cart, setCart] = useState([]);

	let {
		cart,
		setCart,
		purchaseTotal,
		dispatch,
		timer,
		isVisible,
		redirect,
		removeFromCart,
		addToCart,
		remove2FromCart,
		buyerInfo,
	} = useContext(Context);

	console.log(cart);
	useEffect(() => {
		setCart(JSON.parse(localStorage.getItem('cart')));
		if (isVisible) {
			dispatch({ type: 'ISVISIBLE' });
		}
		clearTimeout(timer);
	}, [dispatch, timer, isVisible, setCart]);

	const setOrder = (e) => {
		setNote(e.target.value);

		// // TODO - Refactor this code
		// if (
		//   e.target.hasAttribute("required") &&
		//   e.target.name === "customerAddress" &&
		//   e.target.value.length > 10
		// ) {
		//   if (e.target.value !== "") {
		//     setDisabled(true);
		//   }
		//   setDisabled(false);
		// }
	};

	// const submitOrder = (e) => {
	//   e.preventDefault();

	//   let setOrder = (cart, buyerInfo) => {
	//     let purchaseTotal = cart.reduce(
	//       (acc, item) => acc + item.price * item.qty,
	//       0
	//     );

	//     return { buyerInfo, purchaseTotal, cart };
	//   };
	//   const newOrder = setOrder(cart, buyerInfo);

	//   // setBuyerInfo({});

	//   // dispatch({ type: 'ISLOADING' });

	//   // setTimeout(() => {
	//   // 	dispatch({ type: 'ISLOADING' });
	//   // 	console.log(orderedProduct);
	//   // }, 2000);
	//   // setOrderedProduct({ itemsPurchased: cart, buyerInfo });
	//   // console.log(orderedProduct);
	//   // console.log(buyerInfo);

	//   setTimeout(() => {
	//     setIsLoading(true);
	//     setDisabled(true);
	//   }, 500);

	//   axios
	//     .post("/products/order", newOrder)
	//     .then((res) => {
	//       console.log(res);

	//       if (res.data.success === true) {
	//         setTimeout(() => {
	//           dispatch({ type: "CONFIRM_MODAL", payload: false });
	//           setIsLoading(false);
	//           setCart([]);
	//           dispatch({
	//             type: "CONFIRMATION_MODAL",
	//             payload: res.data.orderNumber,
	//           });
	//           // const modalWindow = document.getElementById('modalWindow');
	//           // modalWindow.scrollIntoView({ block: 'center', inline: 'center' });
	//         }, 3000);
	//       }
	//     })
	//     .catch((err) => {
	//       console.log(err);
	//     });
	// };

	const purchaseItems = cart.map((items) => {
		console.log(items);
		return (
			<div
				key={items._id}
				className='product-content'>
				<img
					src={'/' + items.img}
					alt='product thumbnail'
				/>
				<p>{items.name}</p>
				{/* <p>{items.qty}</p> */}
				<p className='t3 fw400 p01 pr-qty'>
					<span className='s-container'>
						<button
							type='button'
							id='min'
							onClick={() => remove2FromCart(items)}>
							&#8722;
						</button>
						<input
							type='number'
							name='qtyItem'
							id='qtyItem'
							value={items.qty}
							onChange={setOrder}
							className=''
						/>
						<button
							type='button'
							id='plus'
							name='count'
							onClick={() => addToCart(items)}>
							&#43;
						</button>
					</span>
				</p>
				<p>{format(items.price)}</p>
				<p>{format(items.qty * items.price)}</p>
				<TrashCan onClick={() => removeFromCart(items)} />
			</div>
		);
	});

	function CleaveInput(props) {
		const [field, meta] = useField(props);
		return (
			<>
				<label htmlFor='customerPhone'>Telephone Number</label>
				<Cleave
					{...field}
					{...props}
				/>
				{meta.errors && meta.touched ? <div>{meta.errors}</div> : null}
			</>
		);
	}

	return (
		<section className='checkout-section container'>
			<Portal>
				<ConfirmModal
				// submitOrder={submitOrder}
				// setOrder={setOrder}
				// isLoading={isLoading}
				// disabled={disabled}
				/>
			</Portal>
			<div className='checkout content'>
				{/* <form onSubmit={submitOrder}> */}
				<div className='checkout-cart'>
					<div className='checkout-cart-container'>
						<div className='product-table-head'>
							<h1 id='hd-title'>Order Info</h1>
							<h2 id='hd-pr'>Product</h2>
							<h2 id='hd-qt'>Qty</h2>
							<h2 id='hd-up'>Unit Price</h2>
							<h2 id='hd-tp'>Total Price</h2>
							<div className='product-table-body'>
								{/* <div className='product-content'> */}
								{cart.length ? (
									purchaseItems
								) : (
									<div>
										<p>Your shopping cart is empty</p>
									</div>
								)}
								{/* <img src={productDetail.src} className='t1 pr-img' alt='product' />
										<p className='t2 p31 pr-title'>{orderSummary.product}</p>
										<p className='t3 fw400 p01 pr-qty'>
											<span className='s-container'>
												<button type='button' id='min' onClick={decrease}>
													&#8722;
												</button>
												<input
													type='number'
													name='qtyItem'
													id='qtyItem'
													value={qtyCount}
													onChange={qtyValue}
													className=''
												/>
												<button type='button' id='plus' name='count' onClick={increase}>
													&#43;
												</button>
											</span>
										</p>
										<p className='t4 fw400 p31 pr-price'>{format(orderSummary.unidPrice)}</p>
										<p className='t5 fw400 p31 pr-total'>{format(orderSummary.unidPrice * qtyCount)}</p> */}
								{/* </div> */}
							</div>
						</div>
						{
							<div className='customerNote'>
								<h6>Note:</h6>
								<textarea
									name='customerNote'
									cols='50'
									rows='5'
									onChange={setOrder}
									placeholder='Type your note here'
									tabIndex='0'></textarea>
							</div>
						}
						<div className='total-container'>
							<button
								type='button'
								className='submit-btn secondary'
								onClick={() => {
									dispatch({
										type: 'SHOW_SUMMARY',
										payload: false,
									});
								}}>
								go back
							</button>
							<h2>
								<span>total:</span>
								{/* {format(orderSummary.unidPrice * qtyCount)} */}
								{format(purchaseTotal)}
							</h2>
						</div>
					</div>

					<div className='client-details'>
						<div className='cd-container'>
							<h1>Personal Detail</h1>
							<div className='info-container'>
								<div className='info-text'>
									<Formik
										initialValues={buyerInfo}
										validationSchema={personalDetailSchema}
										onSubmit={async (values) => {
											values.customerNote = note;
											setIsLoading(true);
											dispatch({
												type: 'SET_CUSTOMER_INFO',
												payload: values,
											});
											setTimeout(() => {
												setIsLoading(false);
												dispatch({
													type: 'CONFIRM_MODAL',
													payload: true,
												});
											}, 3000);
										}}>
										{({ errors, touched }) => (
											<Form>
												<div className='input-container'>
													<label htmlFor='costumerName'>
														Full Name
													</label>
													<Field name='customerName' />
													{errors.customerName &&
													touched.customerName ? (
														<div className='field-error'>
															{
																errors.customerName
															}
														</div>
													) : (
														<div className='empty-field'></div>
													)}
												</div>
												<div className='input-container'>
													<CleaveInput
														id='customerPhone'
														type='tel'
														name='customerPhone'
														// onChange={setOrder}
														// tabIndex="2"
														options={{
															phone: true,
															phoneRegionCode:
																'US',
															required: true,
														}}
													/>
													{errors.customerPhone &&
													touched.customerPhone ? (
														<div className='field-error'>
															{
																errors.customerPhone
															}
														</div>
													) : (
														<div className='empty-field'></div>
													)}
												</div>

												<div className='input-container'>
													<label htmlFor='customerEmail'>
														Email
													</label>
													<Field name='customerEmail' />
													{errors.customerEmail &&
													touched.customerEmail ? (
														<div className='field-error'>
															{
																errors.customerEmail
															}
														</div>
													) : (
														<div className='empty-field'></div>
													)}
												</div>

												<div className='input-container'>
													<label htmlFor='customerAddress'>
														Address
													</label>
													<Field name='customerAddress' />
													{errors.customerAddress &&
													touched.customerAddress ? (
														<div className='field-error'>
															{
																errors.customerAddress
															}
														</div>
													) : (
														<div className='empty-field'></div>
													)}
												</div>
												<div className='input-container'>
													<label htmlFor='customerReferredBy'>
														Referred By
													</label>
													<Field name='customerReferredBy' />
												</div>

												<div
													style={{
														position: 'relative',
													}}>
													<Spinner
														isLoading={isLoading}
														style={{
															width: '100% !important',
															height: '100%',
															position:
																'absolute',
														}}
													/>
													<div className='btn-container mt-5'>
														<button
															type='submit'
															className='submit-btn primary'
															tabIndex='5'>
															{isLoading
																? 'opening'
																: 'continue to payment'}
														</button>
													</div>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{redirect && <Navigate to='/' />}
		</section>
	);
};
// eslint-disable-next-line
{
	/* <button
          type="button"
          className="cancel-btn secondary"
          // onClick={() => dispatch({ type: 'SHOW_SUMMARY', payload: false })}
        >
          go back
        </button> */
}
// eslint-disable-next-line
{
	/* 
											
										
										<div className='input-container'>
											<label htmlFor='customerEmail'>Email Address</label>
											<input
												id='customerEmail'
												type='email'
												name='customerEmail'
												onChange={setOrder}
												tabIndex='3'
												required
											/>
										</div>
										<div className='input-container'>
											<label htmlFor='customerAddress'>Address</label>
											<input
												id='customerAddress'
												type='text'
												name='customerAddress'
												onChange={setOrder}
												tabIndex='3'
												required
											/>
										</div>
										<div className='input-container'>
											<label htmlFor='referredBy'>Referred By</label>
											<input id='referredBy' type='text' name='referredBy' onChange={setOrder} tabIndex='4' />
										</div> */
}
