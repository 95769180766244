import React, { useReducer, useCallback, useState } from "react";
import axios from "axios";

import Context from "./context";
import Reducer from "./Reducer";

function RandomNumber(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const Provider = ({ children }) => {
  const [ cart, setCart ] = useState( [] );
  
  //todo fix refactor this code to avoid DRY
  // Add item to the cart
  function addToCart(item) {
    let timer = RandomNumber(300, 500);
    const found = cart.find((i) => i.priceId === item.priceId);
    dispatch({ type: "ISLOADING", payload: true });

    setTimeout(() => {
      dispatch({ type: "ISLOADING", payload: false });
      if (found) {
        found.qty += 1;
        localStorage.setItem("cart", JSON.stringify(cart));
        return setCart((prevState) => [...prevState]);
      }

      localStorage.setItem("cart", JSON.stringify(cart));
      setCart((prevState) => [...prevState, item]);
    }, timer);
  }

  // Remove Item form cart
  function removeFromCart(itemID) {
    let deletedItem = cart.filter((item) => item._id !== itemID._id);
    setCart(deletedItem);
    // console.log(cart);
    console.log(itemID);
  }

  // Add quantity if item in cart exist
  // function itemsWithQuantities(items) {

  // 	return items.reduce((acc, item) => {
  // 		console.log(acc)
  // 		console.log(item)
  // 		const found = acc.find((_item) => _item._id === item._id);
  // 		if (found) {
  // 			found.qty += 1;
  // 		} else {
  // 			acc.push({ qty: 1, ...item });
  // 		}
  // 		// localStorage.setItem('cart', JSON.stringify(acc));
  // 		return acc;
  // 	}, []);
  // }

  //todo fix refactor this code to avoid DRY
  // Add quantity if item in cart exist
  function remove2FromCart(item) {
    const newCart = [...cart];
    const found = newCart.find((i) => i._id === item._id);
    dispatch({ type: "ISLOADING", payload: true });
    let timer = RandomNumber(300, 500);
    setTimeout(() => {
      dispatch({ type: "ISLOADING", payload: false });
      if (found.qty === 0) return;
      else if (found) {
        found.qty -= 1;
        localStorage.setItem("cart", JSON.stringify(cart));
      }
      setCart((prevState) => [...prevState]);
      localStorage.setItem("cart", JSON.stringify(cart));
    }, timer);
    // return cart
  }

  const initialState = {
    adminProduct: [],
    productsData: [],
    productDetail: {},
    orderSummary: {},
    dataLoaded: false,
    loading: false,
    selectedProduct: {},
    isVisible: false,
    confirmModal: false,
    // itemToEdit: {},
    setLocalStorage: {},
    filteredItems: [],
    purchaseTotal: 0,
    addItemModal: false,
    showCartModal: false,
    openConfirmationModal: { openModal: false, orderNumber: "" },
    redirect: false,
    customers: [],
    hideComponents: { header: false, hero: false, footer: false },
    buyerInfo: {
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      customerAddress: "",
      customerReferredBy: "",
      customerNote: "",
    },
  };

  const [state, dispatch] = useReducer(Reducer, initialState);

  const getProducts = useCallback(async () => {
    try {
      dispatch({ type: "ISLOADING", payload: true });
      dispatch({ type: "DATA_LOADED", payload: false });
      await axios
        .get("/api/v1/products/", { cancelToken: SourceBuffer.token })
        .then(async ({ data }) => {
          console.log(data);
          dispatch({ type: "PRODUCT_DATA", payload: data.doc });
          dispatch({ type: "ADMIN_DATA", payload: data.forCart });
          // return data;

          await dispatch({ type: "DATA_LOADED", payload: true });

          dispatch({ type: "ISLOADING", payload: false });
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getItemDetail = useCallback(async (itemId) => {
    dispatch({ type: "ISLOADING", payload: true });
    // dispatch({ type: 'DATA_LOADED', payload: false });
    try {
      await axios.get(`/api/v1/products/item/${itemId}`).then(async (data) => {
        console.log(data);
        dispatch({ type: "ITEM_DETAIL", payload: data.data.product });

        await dispatch({ type: "DATA_LOADED", payload: true });

        await dispatch({ type: "ISLOADING", payload: false });

        // console.log(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getCustomers = useCallback(async (itemId) => {
    try {
      dispatch({ type: "ISLOADING", payload: true });
      const fetched = await axios
        .get("/api/v1/admin/customers", { cancelToken: SourceBuffer.token })
        .then((data) => {
          data = data.data.found;
          dispatch({ type: "ISLOADING", payload: false });

          // console.log(data);
          return data;
        });
      await dispatch({ type: "CUSTOMERS_DATA", payload: fetched });
      // console.log(fetched);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getEditProduct = useCallback(async (itemId) => {
    try {
      dispatch({ type: "ISLOADING", payload: true });
      await axios
        .get(`/api/v1/admin/products/item/${itemId}`, {
          cancelToken: SourceBuffer.token,
        })
        .then((data) => {
          console.log(data.data);

          dispatch({ type: "ISLOADING", payload: false });
          dispatch({ type: "ITEM_DETAIL", payload: data.data.doc });
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        productsData: state.productsData,
        getProducts,
        dataLoaded: state.dataLoaded,
        loading: state.loading,
        productDetail: state.productDetail,
        getItemDetail,
        selectedProduct: state.selectedProduct,
        dispatch,
        isVisible: state.isVisible,
        orderSummary: state.orderSummary,
        confirmModal: state.confirmModal,
        // itemToEdit: state.itemToEdit,
        getEditProduct,
        setLocalStorage: state.setLocalStorage,
        getLocalStorage: state.getLocalStorage,
        setCart,
        purchaseTotal: state.purchaseTotal,
        addItemModal: state.addItemModal,
        showCartModal: state.showCartModal,
        timer: state.timer,
        openConfirmationModal: state.openConfirmationModal,
        redirect: state.redirect,
        getCustomers,
        customers: state.customers,
        hideComponents: state.hideComponents,
        cart,
        addToCart,
        remove2FromCart,
        removeFromCart,
        RandomNumber,
        buyerInfo: state.buyerInfo,
        adminProduct: state.adminProduct,
        
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
