import {
  Button,
  Container,
  Input,
  Paper,
  PasswordInput,
  Stack,
  Title,
} from "@mantine/core";
import { Form, useLocation, useNavigate} from "react-router-dom";
import { IconEyeCheck, IconEyeOff, IconLock, IconUser } from "@tabler/icons";
import {useState } from "react";

import { useLogin } from "../../context/UserLogin";

const LoginPage = ({ content }) => {
  const [formData, setFormData] = useState({ username: "", password: "" });

  const { loginUser } = useLogin();
  const navigate = useNavigate()
  const location = useLocation()

  const redirectPath = location.state?.pathname || '/admin/products'

  const handleUserLogin = (evt) => {
    evt.preventDefault();
    setFormData((prev) => ({ ...prev, [evt.target.name]: evt.target.value }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    loginUser( formData );
    navigate(redirectPath,{replace:true})
  };

  return (
    <Container px="xs" size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({
          // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Xomos1 Admin Login
      </Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Form onSubmit={handleSubmit}>
          <Stack>
            <Input
              name="email"
              placeholder="Email"
              size="lg"
              icon={<IconUser />}
              type="text"
              autoComplete="off"
              onChange={handleUserLogin}
            />
            <PasswordInput
              name="passwd"
              autoComplete="none"
              onChange={handleUserLogin}
              size="lg"
              placeholder="Password"
              icon={<IconLock />}
              visibilityToggleIcon={({ reveal, size }) =>
                reveal ? (
                  <IconEyeOff size={size} />
                ) : (
                  <IconEyeCheck size={size} />
                )
              }
            />
            <Button fullWidth mt="xl" type="submit">
              Sign in
            </Button>
          </Stack>
        </Form>
      </Paper>
    </Container>
  );
};

export default LoginPage;
