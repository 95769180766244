import { createStyles, Group, Image, Paper, SimpleGrid, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';

const useStyles = createStyles((_, url) => ({
	card: {
		height: '100%',
		// display: 'flex',
		// flexDirection: 'column',
		// justifyContent: 'space-between',
		// alignItems: 'flex-start',
		backgroundColor: '#EEEEEE',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		transition: 'all .2s ease',
		backgroundImage: `url(${url})`,
	},
	tumbs: {
		cursor: 'pointer',
	},
}));

function Carousel({ images }) {
	const smallViewport = useMediaQuery('(min-width: 678px)');

	const [url, setUrl] = useState(images[0].originalURL);
	const [imageIndex, setImageIndex] = useState(0);

	const { classes } = useStyles( url );
	
	return (
		<Stack
			style={{ overflow: 'hidden' }}
			spacing={5}>
			<Group
				spacing={0}
				style={{
					// width: '100%',
					height: smallViewport ? '478px' : '300px',
					flexWrap: 'nowrap',
					display: 'inline-block',
				}}>
				<Paper
					// p='xl'
					className={classes.card}
				/>
			</Group>

			<SimpleGrid cols={6}
				sx={{
					'flexWrap': 'nowrap',
					'& .active': { opacity: 1 },
					height: '100px'
				}}
				spacing={5}>
				{images.map((image, idx) => (
					<Image
						sx={ {
							// '.mantine-Image-root': {height: '100px !important'},
							'cursor': 'pointer',
							'&:hover': { opacity: 1 },
							'transition': 'opacity .3s ease',
							'opacity': 0.3,
							'width': smallViewport ? 100 : 75,
							height: '100px !important',
							maxHeight: '100px !important'
							// 'height': smallViewport ? 100 : '100px !important',
						}}
						className={imageIndex === idx ? 'active' : ''}
						key={image.imageName}
						src={image.originalURL}
						onClick={() => {
							setImageIndex(idx);
							setUrl(image.originalURL);
						}}
					/>
				))}
			</SimpleGrid>
		</Stack>
	);
}

export default Carousel;
