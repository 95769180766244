import React from 'react';
import { NavLink } from 'react-router-dom';

export const LeftSidebar = () => {
	// let { url } = useMatch();

	return (
		<>
			{/* <div id="main-wrapper"> */}
			{/* <AdminDashboardHeader /> */}
			<aside
				className='left-sidebar'
				style={{ background: '#1F262D' }}
				data-sidebarbg='skin5'>
				{/* Sidebar scroll*/}
				<div className='scroll-sidebar'>
					{/* Sidebar navigation*/}
					<nav className='sidebar-nav'>
						<ul
							id='sidebarnav'
							className='p-t-30'>
							<li className='sidebar-item'>
								<NavLink
									// activeClassName='active'
									className={`sidebar-link waves-effect waves-dark sidebar-link `}
									to={`/admin/products`}
									aria-expanded='false'>
									<i className='mdi mdi-view-dashboard' />
									<span className='hide-menu'>Products</span>
								</NavLink>
							</li>
							{/* <li className="sidebar-item">
                <NavLink
                  // activeClassName='active'
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to={`/admin/customers`}
                  aria-expanded="false"
                >
                  <i className="mdi mdi-account-multiple" />
                  <span className="hide-menu">Customers</span>
                </NavLink>
              </li>
              <li className="sidebar-item">
                <NavLink
                  // activeClassName='active'
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to={`/admin/invoice`}
                  aria-expanded="false"
                >
                  <i className="mdi mdi-receipt" />

                  <span className="hide-menu">Invoice</span>
                </NavLink>
              </li> */}
						</ul>
					</nav>

					{/* End Sidebar navigation */}
				</div>
				{/* End Sidebar scroll*/}
			</aside>
			{/* </div> */}
			{/* <Outlet /> */}
		</>
	);
};
