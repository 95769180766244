import { Modal, MediaQuery } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddNewProductForm from './AddNewProductForm';

const styles = {
	modal: {
		fontFamily: "Figtree, 'Noto Sans', sands-serif",
	},
	title: {
		fontFamily: "Figtree, 'Noto Sans', sands-serif",
		fontWeight: 700,
		fontSize: '2.5rem',
	},
};

function AddNewProduct() {
	const { state } = useLocation();
	const [openModal, setOpenModal] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		state.openModal && setOpenModal(true);
	}, [state.openModal]);

	const handleCloseModal = () => {
		setOpenModal(false);
		navigate('/admin/products');
	};

	return (
		<MediaQuery
			smallerThan='md'
			styles={{
				'.mantine-Modal-modal': { width: '600px' },
			}}>
			<Modal
				size={1200}
				opened={openModal}
				onClose={handleCloseModal}
				styles={styles}
				title='New Product'
				closeOnClickOutside={false}>
				<AddNewProductForm closeModal={handleCloseModal} />
			</Modal>
		</MediaQuery>
	);
}

export default AddNewProduct;
