import { createContext, useContext, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../utils/encypt";

const SECRET = process.env.REACT_APP_SECRET;

const UserLoggedContext = createContext();

const userLoginReducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "IS_LOGGED_IN":
      return { ...state, isUserLoggedIn: true };

    default:
      return state;
  }
};

const UserLoggedIn = ({ children }) => {
  const [state, dispatch] = useReducer(userLoginReducer, {
    isUserLoggedIn: false,
  });

  const navigate = useNavigate();

  const loginUser = (data) => {
    const user = decrypt("user", SECRET);

    if (user.email === data.email && user.passwd === data.passwd) {
      user.isLoggedIn = true;
      encrypt("user", user, SECRET);
      dispatch({ type: "IS_LOGGED_IN" });
      navigate("/admin/products");
    }
  };

  const value = useMemo(
    () => ({
      isUserLoggedIn: state.isUserLoggedIn,
    }),

    [state]
  );

  return (
    <UserLoggedContext.Provider value={{ value, loginUser, dispatch }}>
      {children}
    </UserLoggedContext.Provider>
  );
};

export { UserLoggedIn, UserLoggedContext };

export const useLogin = () => {
  return useContext(UserLoggedContext);
};
