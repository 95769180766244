import React from 'react';

export const CashAppLogo = ({ className }) => {
	return (
		<span className={className}>
			{/* <svg xmlns='http://www.w3.org/2000/svg' height='50' width='150' fill='none' /* viewBox='-20.734545 -7.661725 179.69939 45.97035' > */}
			<svg xmlns='http://www.w3.org/2000/svg' height='40' width='150' fill='none' viewBox='-15 -7 150 45'>
				<path
					fill='#00D54B'
					d='M19.9538 0c3.0917 0 4.6127 0 6.2826.5247a6.5026 6.5026 0 013.9 3.9c.5247 1.6558.5247 3.191.5247 6.2684v9.2607c0 3.074 0 4.6091-.5247 6.2684a6.5029 6.5029 0 01-3.9 3.9c-1.6558.5247-3.1909.5247-6.2684.5247h-9.2891c-3.0739 0-4.609 0-6.2684-.5247a6.5026 6.5026 0 01-3.9-3.9C0 24.5664 0 23.0454 0 19.9538V10.679C0 7.605 0 6.07.5247 4.4105a6.5024 6.5024 0 013.9-3.9C6.0663 0 7.605 0 10.679 0z'
					clipRule='evenodd'
					fillRule='evenodd'
				/>

				<path
					fill='#fff'
					d='M16.0964 10.0444a6.3816 6.3816 0 014.1127 1.5103.6275.6275 0 00.8615-.0177l1.1842-1.1877a.606.606 0 00-.0319-.89 9.3881 9.3881 0 00-3.1661-1.7726l.3546-1.748a.6172.6172 0 00-.6063-.741h-2.2797a.6205.6205 0 00-.6063.4858L15.6 7.247c-3.0349.149-5.6089 1.6522-5.6089 4.7332 0 2.6626 2.1273 3.8078 4.3751 4.6091 2.1272.7906 3.2547 1.085 3.2547 2.1982s-1.1239 1.8152-2.7796 1.8152a6.1192 6.1192 0 01-4.3219-1.691.6169.6169 0 00-.8651 0L8.385 20.1524a.6133.6133 0 000 .8828 8.4061 8.4061 0 003.6695 2.0103L11.7 24.6658a.631.631 0 00.6027.7588h2.2868a.6206.6206 0 00.6099-.4893l.3297-1.5352c3.6376-.2234 5.8606-2.1804 5.8606-5.0452 0-2.6378-2.2159-3.751-4.9069-4.6587-1.5387-.5779-2.8683-.9573-2.8683-2.0989s1.2409-1.5529 2.4819-1.5529z'
				/>
				<path
					fill='#000'
					d='M55.4792 11.8667a.5709.5709 0 01-.7552-.1489 4.3819 4.3819 0 00-3.7227-1.8755c-2.8363 0-4.5772 2.2442-4.5772 5.4493s1.7728 5.492 4.6091 5.492a4.3325 4.3325 0 003.7298-1.9607.5637.5637 0 01.7446-.1666l1.3473.78a.5742.5742 0 01.2091.8438 7.0091 7.0091 0 01-6.0272 3.1164c-4.5347 0-7.6086-3.29-7.6086-8.087s3.0739-8.0448 7.5412-8.0448a7.1442 7.1442 0 015.9989 2.9711.5775.5775 0 01-.156.8296z'
				/>
				<path
					fill='#000'
					d='M65.3923 21.8755v.6523a.5676.5676 0 00.5921.5673h1.482a.5673.5673 0 00.5673-.5673v-7.41c0-2.6839-1.9074-3.7936-4.6091-3.7936a9.311 9.311 0 00-4.3538 1.092.5708.5708 0 00-.234.7516l.4857 1.014a.5603.5603 0 00.7729.2447 6.964 6.964 0 013.0952-.7836c.9396 0 2.0493.259 2.0493 1.2197 0 1.005-1.214 1.1764-2.7118 1.3878a98.2918 98.2918 0 00-.3621.0516c-1.5458.2163-4.0525.6985-4.0525 3.51 0 2.2585 1.6345 3.5455 3.9887 3.5455 1.5919 0 2.5811-.5673 3.2901-1.482zm-2.3235-3.821c.8953-.1572 1.7073-.2996 2.1711-.6498v1.319c0 1.723-1.2409 2.464-2.5705 2.464-1.0707 0-1.8117-.4787-1.8117-1.5033 0-1.1133.8509-1.3863 1.8756-1.5706.1127-.0203.2247-.04.3355-.0594z'
					clipRule='evenodd'
					fillRule='evenodd'
				/>
				<path
					fill='#000'
					d='M70.4694 20.1595a.5674.5674 0 01.7836-.0744 5.2726 5.2726 0 003.2973 1.0636c1.2231 0 2.1272-.3935 2.1272-1.3508 0-.8722-.8296-1.0459-2.8363-1.3969-2.1273-.3546-4.1624-1.0637-4.1624-3.4887 0-2.528 2.1805-3.595 4.6658-3.595a6.9808 6.9808 0 014.3219 1.418.5671.5671 0 01.078.819l-.7091.8332a.5708.5708 0 01-.78.0887 5.002 5.002 0 00-3.0136-.9644c-1.0459 0-1.9606.305-1.9606 1.131 0 .9183 1.2834 1.092 2.5704 1.3118 2.7477.4787 4.4921 1.2622 4.4921 3.5455 0 2.3967-1.9181 3.8787-4.9636 3.8787a7.1506 7.1506 0 01-4.6091-1.5033.5743.5743 0 01-.0745-.8154zm10.5584 2.3683v-14.43a.5672.5672 0 01.5672-.5673h1.6345c.3133 0 .5673.254.5673.5673v4.9282a4.2546 4.2546 0 013.51-1.7019c2.418 0 3.8361 1.7231 3.8361 4.1837v7.02a.5672.5672 0 01-.5672.5673h-1.6345a.5673.5673 0 01-.5673-.5673V16.146c0-1.2232-.3935-2.3329-1.9606-2.3329-1.4855 0-2.6165 1.046-2.6165 2.7902v5.928a.5673.5673 0 01-.5673.5673H81.595a.5638.5638 0 01-.5672-.5708z'
				/>
				<path
					fill='#000'
					d='M107.9523 19.2376l1.177 3.4746a.5672.5672 0 00.528.3971h2.025a.5659.5659 0 00.466-.2413.5662.5662 0 00.069-.521l-5.336-14.43a.5744.5744 0 00-.535-.3723h-2.62a.5664.5664 0 00-.532.3546l-5.3575 14.4335a.5674.5674 0 00.5318.7623h1.9177a.564.564 0 00.536-.3829l1.18-3.4746zm-5.233-2.4215l2.29-6.7576 2.202 6.7576zm13.607-3.7688v-.8935a.5634.5634 0 00-.156-.4195.5666.5666 0 00-.411-.176h-1.635c-.313 0-.567.254-.567.5672v14.1818c0 .3133.254.5673.567.5673h1.635c.313 0 .567-.254.567-.5673v-4.7048a4.1593 4.1593 0 003.546 1.7443c3.137 0 5.165-2.627 5.165-6.0272 0-3.4-2.028-5.9954-5.165-5.9954a4.1669 4.1669 0 00-3.546 1.7231zm5.8 4.2723c0 1.982-.918 3.62-2.836 3.62-1.953 0-2.964-1.6346-2.964-3.62 0-1.9855 1.064-3.5774 2.964-3.5774s2.836 1.5955 2.836 3.5774zm7.389-4.2724v-.8935a.5643.5643 0 00-.154-.4207.5649.5649 0 00-.413-.1749h-1.635a.5672.5672 0 00-.567.5672v14.182c0 .3132.254.5671.567.5671h1.635c.313 0 .567-.2539.567-.5672v-4.7049a4.1675 4.1675 0 003.546 1.7444c3.141 0 5.169-2.6272 5.169-6.0273 0-3.4-2.028-5.9953-5.169-5.9953a4.1736 4.1736 0 00-3.546 1.7231zm5.804 4.2722c0 1.982-.918 3.62-2.836 3.62-1.943 0-2.968-1.6345-2.968-3.62 0-1.9854 1.067-3.5773 2.968-3.5773 1.9 0 2.836 1.5954 2.836 3.5773z'
					clipRule='evenodd'
					fillRule='evenodd'
				/>
			</svg>
		</span>
	);
};
