import React, {
	useEffect,
	useState,
	/* useContext, */ useCallback,
} from 'react';

// import Context from '../context/context';
// import axios from "axios";
import { format } from '../utils/format';

// import ImageGallery from './imageGallery';
import { useLoaderData, useMatches } from 'react-router-dom';
import { getSingleProduct } from '../utils/fetchProducts';
import {
	ActionIcon,
	Breadcrumbs,
	Button,
	createStyles,
	Divider,
	Grid,
	Group,
	List,
	NumberInput,
	Stack,
	Tabs,
	Text,
	Title,
} from '@mantine/core';
import { TypographyStylesProvider } from '@mantine/core';
import { Ratings } from './Ratings/Ratings';
import Carousel from './Carousel/Carousel';

import { CgShoppingBag } from 'react-icons/cg';
import { useMediaQuery } from '@mantine/hooks';
// import { IconChevronDownRight, IconChevronRight } from '@tabler/icons';
import { FiChevronRight } from 'react-icons/fi';
import { useCartContext } from '../context/CartContext';

const useStyle = createStyles((theme, matches) => ({
	listItem: {
		'textTransform': 'uppercase',
		'color': '#808080',
		'& span': {
			color: 'black',
		},
	},
	minusButton: {
		'width': matches ? 42 : 30,
		'borderTopRightRadius': 0,
		'borderBottomRightRadius': 0,
		'fontSize': 24,
		'&:active': {
			transform: 'translateY(0)',
		},
	},
	plusButton: {
		'width': matches ? 42 : 30,
		'borderTopLeftRadius': 0,
		'borderBottomLeftRadius': 0,
		'fontSize': 24,
		'&:active': {
			transform: 'translateY(0)',
		},
	},
	actionButtons: {
		'flexWrap': 'nowrap',
		'.mantine-ActionIcon-root': {
			width: matches ? 30 : 42,
			minWidth: matches ? 30 : 42,
		},
	},
	numberInput: {
		input: {
			width: matches ? 40 : 54,
			height: 42,
			textAlign: 'center',
			borderRadius: 0,
			borderInline: 0,
			fontWeight: 700,
			fontSize: 20,
		},
	},
	tab: { fontSize: 20, fontWeight: 700 },
}));

export const ProductDetails = (props) => {
	const productDetail = useLoaderData();

	const { cart } = useCartContext();
	const cartQty = cart.filter((item) => item._id === productDetail._id)[0]
		?.qty;
	const [productCount, setProductCount] = useState(cartQty || 1);
	const { addToCart } = useCartContext();

	const matches = useMediaQuery('(max-width: 900px)');

	const match = useMatches();

	const crumbs = match
		.filter((m) => Boolean(m.handle?.crumb))
		.map((match) => match.handle.crumb(match.data))
		.map((crumb, index) => (
			<React.Fragment key={index}>{crumb}</React.Fragment>
		));

	const { classes } = useStyle(matches);

	//   const params = useParams();

	// const [activeClass, setActiveClass] = useState(0);

	const [, /* productPrice */ setProductPrice] = useState({
		...productDetail?.price,
		...productDetail?.img,
	});

	const setData = useCallback(() => {
		setProductPrice({
			_id: productDetail?._id,
			...productDetail?.price?.startingPrice,
			img: productDetail.img,
		});
	}, [setProductPrice, productDetail]);

	useEffect(() => {
		setData();
	}, [setData]);

	const decreaseCounter = () => {
		if (productCount <= 1) return;
		setProductCount((curr) => --curr);
	};
	const increaseCounter = () => {
		if (productCount >= productDetail.stockQty) return;
		setProductCount((curr) => ++curr);
	};

	return (
		<>
			<Breadcrumbs
				separator={<FiChevronRight />}
				py={15}>
				{crumbs}
			</Breadcrumbs>
			<Grid>
				<Grid.Col
					// span={4}
					sm={6}
					lg={5}
					// style={{ maxWidth: 475 }}
					mb={40}>
					<Carousel images={productDetail.images} />
				</Grid.Col>
				<Grid.Col
					sm={6}
					lg={7}>
					<Stack px={10}>
						<Title
							order={1}
							weight={700}>
							{productDetail?.title}
						</Title>
						<Group>
							<Ratings
								rate={4}
								size={22}
								color='#FD5B5A'
							/>
							<Text>(1 Reviews)</Text>
						</Group>
						<Group spacing={5}>
							<Text
								size={30}
								weight={700}>
								{format(productDetail.price.sales)}
							</Text>
							<Text
								strikethrough
								color='dimmed'
								size={20}
								weight={700}>
								{format(productDetail.price.retail)}
							</Text>
						</Group>
						<Text
							size={20}
							weight={500}
							color='gray'>
							{productDetail.shortTitle}
						</Text>
						<List
							listStyleType='none'
							className={classes.listItem}>
							<List.Item>
								SKU:&nbsp;
								<Text
									component='span'
									weight={900}>
									{productDetail.productCode}
								</Text>
							</List.Item>
							<List.Item>
								Category:&nbsp;
								<Text
									component='span'
									weight={900}>
									{productDetail.category}
								</Text>
							</List.Item>
						</List>
						<Divider />
						<Group
							spacing={15}
							py={14}
							className={classes.actionButtons}>
							<Group spacing={0}>
								<ActionIcon
									onClick={decreaseCounter}
									sx={classes.minusButton}
									size={42}
									variant='default'>
									&minus;
								</ActionIcon>
								<NumberInput
									hideControls
									value={productCount}
									min={1}
									max={productDetail.stockQty}
									sx={classes.numberInput}
								/>
								<ActionIcon
									onClick={increaseCounter}
									sx={classes.plusButton}
									size={42}
									variant='default'>
									&#43;
								</ActionIcon>
							</Group>
							<Button
								onClick={() =>
									addToCart(productDetail._id, productCount)
								}
								uppercase
								color='dark'
								radius='xs'
								size={matches ? 'md' : 'lg'}
								px={matches ? 'md' : 'lg'}
								leftIcon={<CgShoppingBag size={25} />}>
								add to cart
							</Button>
						</Group>
						<Divider />
					</Stack>
				</Grid.Col>

				<Tabs
					color='dark'
					radius='xs'
					defaultValue='description'
					styles={{ tab: classes.tab }}>
					<Tabs.List>
						<Tabs.Tab value='description'>Description</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel
						value='description'
						pt='md'
						px='lg'>
						<TypographyStylesProvider>
							<div
								dangerouslySetInnerHTML={{
									__html: productDetail?.desc,
								}}
							/>
						</TypographyStylesProvider>
					</Tabs.Panel>
				</Tabs>
			</Grid>
		</>
	);
};

export const singleProductDetail = async (params) => {
	console.log(params);
	const singleProduct = await getSingleProduct(params);
	return singleProduct;
};
