import React, { useEffect } from 'react';

import Provider from './context/Provider';

import { AppHeader } from './Components/Header';
import { Hero } from './Components/hero';
import { Footer } from './Components/footer';
import { Outlet, useLocation } from 'react-router-dom';

import { decrypt, encrypt } from './utils/encypt';
import { Category } from './Components/LeftNavbar';
import { Products } from './Components/products';

const ENV = process.env.NODE_ENV;

export const URLSUFIX =
	ENV === 'production'
		? 'https://xomos1.com/'
		: 'https://xomos1.leonelmatos.com/';

// local stored data for userdata
const secret = process.env.REACT_APP_SECRET;
const email = process.env.REACT_APP_EMAIL;
const passwd = process.env.REACT_APP_PASSWD;

// decrypt the stored data
const user = decrypt('user', secret);

// check if the is an user object if not create it
if (!user) {
	encrypt('user', { email, passwd, isLoggedIn: false }, secret);
}
function App() {
	const location = useLocation();

	useEffect(() => {
		process.env.NODE_ENV === 'development'
			? (document.title = 'Xomos1 DevServer')
			: (document.title = 'Xomos1.com');
	}, []);

	return (
		// <div className='main App full-width'>
		<>
			<Provider>
				{/* <Portal>
					<Dialog
          width='60rem'
          height='40rem'
					/>
				</Portal> */}
				<AppHeader />

				<Hero />
				{/* <Container
					size={1400}
					px={0}
					my={40}> */}
				<main
					style={{
						// height: '100vh',
						maxWidth: '1400px',
						marginLeft: 'auto',
						marginRight: 'auto',
						paddingLeft: 0,
						paddingRight: 0,
						// marginTop: '40px',
						marginBottom: '40px',
						display: location.pathname === '/' ? 'flex' : '',
					}}>
					<Category />
					{location.pathname === '/' ? <Products /> : <Outlet />}
				</main>
			</Provider>
			<Footer />
		</>
	);
}

export default App;
