import React, { useEffect } from 'react';
import { ProductCard } from './card';
// import axios from 'axios';
import { useProductsContext } from '../context/ProductsContext';
import { SimpleGrid, createStyles } from '@mantine/core';

const useStyle = createStyles({
	grid: { width: '100%', marginTop: 55 },
});
export const Products = () => {
	const { classes } = useStyle();
	const {
		value: { products },
		getProducts,
	} = useProductsContext();

	useEffect(() => {
		// const CancelToken = axios.CancelToken;
		// const source = CancelToken.source();
		getProducts();
		return () =>
			// source.cancel();
			getProducts();
		// eslint-disable-next-line
	}, []);

	return (
		<SimpleGrid
			cols={4}
			className={classes.grid}
			breakpoints={[
				{ maxWidth: 980, cols: 4, spacing: 'md' },
				{ maxWidth: 755, cols: 3, spacing: 'sm' },
				{ maxWidth: 600, cols: 2, spacing: 'sm' },
			]}>
			{products.map((element, idx) => {
				return (
					!element.isOutStock && (
						<ProductCard
							data={element}
							key={element._id}
						/>
					)
				);
			})}
		</SimpleGrid>
	);
};
