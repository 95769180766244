import { useEffect } from 'react';
import { useNavigate, Outlet, Navigate, useLocation } from 'react-router-dom';
import { useLogin } from '../../context/UserLogin';
import { decrypt } from '../../utils/encypt';

function ProtectedRoute() {
	let { isUserLoggedIn } = useLogin();
	let user = decrypt('user', process.env.REACT_APP_SECRET);
	const isLoggedOn = isUserLoggedIn ? isUserLoggedIn : user.isLoggedIn;
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		if (isLoggedOn && location.pathname === '/admin') {
			navigate('/admin/products');
		}
		// eslint-disable-next-line
	}, [isLoggedOn, location.pathname, navigate]);

	if (isLoggedOn) {
		return <Outlet />;
	}
	return (
		<Navigate
			to='/login'
			state={{ path: location.pathname }}
			replace
		/>
	);
}

export default ProtectedRoute;
