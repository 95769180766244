import React, { useContext, useState } from "react";
import Context from "../context/context";

import { Navigate, Link } from "react-router-dom";

import { Formik, Field, Form } from "formik";

import "../assets/css/modal.css";
// import checkMarkAnimation from '../assets/images/done-checkmark.gif';
import { CashAppLogo } from "./assets/Logos/CashAppLogo";
import { ZelleLogo } from "./assets/Logos/ZelleLogo";
import { PaypalLogo } from "./assets/Logos/PaypalLogo";
import { SendIcon } from "./assets/SendIcon";

import { Spinner } from "./assets/spinner";
import axios from "axios";

export const ConfirmModal = ({
  // // submitOrder,
  // // setOrder,
  // // isLoading,
  disabled,
}) => {
  // // const [goToHome /* setGoToHome */] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
 
  // let history = useHistory();
  const modal = useContext(Context);
  let { confirmModal, buyerInfo, dispatch, cart, setCart, purchaseTotal } =
    modal;
  console.log(buyerInfo);
  // const reloadPage = () => {
  	// history.push('/home');
  // 	return <Redirect to='/' />;
  // };
  // confirmModal = true

  // const renderRedirect = () => {
  //   setRedirect(true);
   //   dispatch({ type: "CONFIRM_MODAL", payload: false });
    // setCart([]);
  //   return <Redirect to='/'/>
  // };

    // {/* <img src={checkMarkAnimation} alt='checkmark gif animation' /> */}
  return (
    <>
      {confirmModal ? (
        <div className="modal">
          <div className="modal-bg">
            <div id="modalWindow" className="modal-container">
              <div className="modal-content">
              
                <div className="modal-text">
                  <h1 className="title">PAYMENT</h1>
                  <b>
                    <em>
                      <p>
                        For the moment we're only accepting the form of payment
                        shown below.
                      </p>
                    </em>
                  </b>
                  <br />
                  <p>Please follow the steps to make a payment.</p>
                  <br />
                  <p>
                    <span className="number-steps">
                      <span className="number-steps__one">1</span> Please use
                      one of the following payment method to send the payment.
                    </span>
                    <br />

                    <span className="logos-container">
                      <Link
                        className="logos"
                        to={{
                          pathname:
                            "https://cash.app/login?return_to=account.index",
                        }}
                        target="_blank"
                      >
                        <CashAppLogo />
                      </Link>

                      <ZelleLogo className="logos" />
                      <Link
                        className="logos"
                        to={{ pathname: "https://www.paypal.com/us/signin" }}
                        target="_blank"
                      >
                        <PaypalLogo className="logos" />
                      </Link>
                    </span>
                    <br />
                    <span className="number-steps">
                      <span className="number-steps__two">2</span>
                      <span>
                        Send the payment to &nbsp;<b>wexomos1@gmail.com</b> or
                        to the phone number: <b>929-346-1388</b>. Use{" "}
                        <b>Jose Portes</b> as the receiving person.
                      </span>
                    </span>
                    <br />
                    <span className="number-steps">
                      <span className="number-steps__three">3</span>Check your
                      email associated to your app, look for the confirmation or
                      idetifier number and enter that number in the box below.
                    </span>
                  </p>
                </div>

                <Formik
                  initialValues={{ confirmationNumber: "" }}
                  onSubmit={async (values) => {
                    const customerData = {
                      buyerInfo: { ...buyerInfo, ...values },
                      cart,
                      purchaseTotal,
                    };

                    setTimeout(() => {
                      axios
                        .post("/products/create-order", customerData)
                        .then((response) => {
                          if (response.data.success === true) {
                            setCart([]);
                            setIsLoading(false);
                            dispatch({ type: "CONFIRM_MODAL", payload: false });
                            dispatch({ type: "ISLOADING", payload: true });
                            dispatch({
                              type: "SET_CUSTOMER_INFO",
                              payload: response.data.buyerInfo,
                            });
                            setTimeout(() => {
                              setRedirect(true);
                              dispatch({ type: "ISLOADING", payload: false });
                              localStorage.setItem("cart", JSON.stringify([]));
                            }, 1500);
                          }
                          console.log(response);
                        });
                    }, 2000);
                  }}
                >
                  <Form>
                    <div className="input-container">
                      <label htmlFor="confirmationNumber"></label>
                      <Field
                        type="text"
                        name="confirmationNumber"
                        id="confirmationNumber"
                        placeholder="CONFIRMATION or identifier NUMBER"
                        // onChange={setOrder}
                        required
                        autoFocus /* disabled={disabled} */
                      />
                    </div>

                    <div className="btn-container">
                      <Spinner isLoading={isLoading} />
                      <button
                        type="submit"
                        id="ok-btn"
                        className="submit-btn primary"
                        onClick={() => setIsLoading(true)}
                      >
                        {isLoading ? (
                          "proccesing..."
                        ) : (
                          <>
                            {" "}
                            Send <SendIcon />
                          </>
                        )}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      ) : redirect ? (
          <Navigate
            to='/order/confirmation'
            params={buyerInfo.orderNumber}
            state={ buyerInfo }
        />
      ) : null}
    </>
  )

}
