import axios from 'axios';
import { createContext, useContext, useMemo, useReducer } from 'react';

const ProductContext = createContext();

const deleteProductFromState = (state, payload) => {
	console.log(payload);
	const newState = state;
	newState.responseMsg = payload.message;
	newState.products = newState.products.filter((product) => {
		return product._id !== payload._id;
	});

	return { ...newState };
};

const updateProductState = (state, payload) => {
	let newState = state;
	let updatedProductIndex = newState.products.findIndex(
		(product) => product._id === payload._id
	);
	newState.products[updatedProductIndex] = payload;
	return { ...newState };
};

const productReducer = (state, action) => {
	switch (action.type) {
		case 'ERROR_RESPONSE':
			return {
				...state,
				responseMsg: action.payload,
			};
		case 'IS_LOADING':
			return {
				...state,
				isLoading: !state.isLoading,
			};

		case 'DELETE_PRODUCT':
			return deleteProductFromState(state, action.payload);

		case 'UPDATE_PRODUCT':
			return updateProductState(state, action.payload);
		case 'PRODUCTS_DATA':
			return {
				...state,
				products: action.payload,
			};

		case 'SINGLE_PRODUCT':
			return {
				...state,
				singleProduct: { name: 'test product', price: 24 },
			};

		default:
			return state;
	}
};

const ProductsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(productReducer, {
		products: [],
		singleProduct: {},
		isLoading: false,
		responseMsg: '',
	});

	// const getSingleProduct = async (id) => {
	// 	try {
	// 		const { data } = await axios.get(`/api/v1/product/${id}`, {
	// 			cancelToken: SourceBuffer.token,
	// 		});
	// 		return data.doc;
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };


	const updateProduct = async (productId, updatedData) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		const res = await axios.put(
			`/api/v1/product/${productId}/update`,
			updatedData,
			config
		);

		dispatch({ type: 'UPDATE_PRODUCT', payload: res.data.doc });
	};

	const deleteProduct = async (id) => {
		try {
			return await axios
				// .delete(`/api/v1/product/${id}/delete`)
				.delete(`/api/v1/product/${id}/delete`)
				.then((response) => {
					if (response.status === 200) {
						dispatch({
							type: 'DELETE_PRODUCT',
							payload: response.data.doc,
						});
					} else {
						dispatch({
							type: 'ERROR_RESPONSE',
							payload: response.data.message,
						});
					}
				});
		} catch (error) {
			dispatch({
				type: 'ERROR_RESPONSE',
				payload: 'There are an error trying to check',
			});
		}
	};

	const getProducts = async () => {
		try {
			// dispatch({ type: "ISLOADING", payload: true });
			const products = await axios.get(`/api/v1/products`, {
				cancelToken: SourceBuffer.token,
			});
			dispatch({ type: 'PRODUCTS_DATA', payload: products.data.doc });
		} catch (error) {
			console.log(error);
		}
	};

	const value = useMemo(
		() => ({
			products: state.products,
			singleProduct: state.singleProduct,
			isLoading: state.isLoading,
			responseMsg: state.responseMsg,
		}),
		[state]
	);
	return (
		<ProductContext.Provider
			value={{
				value,
				dispatch,
				updateProduct,
				deleteProduct,
				getProducts,
			}}>
			{children}
		</ProductContext.Provider>
	);
};

export { ProductsProvider };

export const useProductsContext = () => {
	return useContext(ProductContext);
};
