import {
	ActionIcon,
	Center,
	FileButton,
	Group,
	Image,
	SimpleGrid,
	Stack,
	Text,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import {
	IconUpload,
	IconPhoto,
	IconX,
	IconReplace,
	IconTrash,
} from '@tabler/icons';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useState } from 'react';

export default function ImageUploader({ updateImageFiles, images = [] }) {
	const theme = useMantineTheme();
	const [imageFiles, setImageFiles] = useState(images);
	const previews = imageFiles.map((file, index) => {
		const imageUrl = file.originalURL
			? file.originalURL
			: URL.createObjectURL(file);
		return (
			<Stack
				key={index}
				style={{
					width: '100%',
					position: 'relative',
					justifyContent: 'center',
				}}>
				<Group
					sx={{
						'opacity': 0,
						'position': 'absolute',
						'zIndex': 1,
						'width': '100%',
						'height': '100%',
						'background': 'rgba(0,0,0,.5)',
						'display': 'flex',
						'justifyContent': 'center',
						'alignItems': 'center',
						'transition': '.5s ease',
						'cursor': 'pointer',

						'&:hover': {
							opacity: 1,
						},
						'.mantine-ActionIcon-root': {
							'transition': '.5s ease',
							'color': 'white',
							'svg': {
								stroke: 'white',
							},
							'&:hover': {
								color: '#5e5d5d',
								background: '#b8b8b89d',
							},
						},
					}}>
					<Tooltip label='Change'>
						<FileButton
							// resetRef={resetRef}
							onChange={(files) => {
								let newArr = imageFiles;
								newArr.splice(index, 1, files);

								setImageFiles([...newArr]);
								updateImageFiles(imageFiles);
								console.log(files);
							}}
							accept={IMAGE_MIME_TYPE}>
							{(props) => (
								<ActionIcon
									{...props}
									variant='subtle'
									size={60}
									radius='xl'>
									<IconReplace size={32} />
								</ActionIcon>
							)}
						</FileButton>
					</Tooltip>
					<Tooltip label='Delete'>
						<ActionIcon
							variant='subtle'
							size={60}
							radius='xl'
							onClick={() => {
								let copyArr = imageFiles;
								copyArr.splice(index, 1);
								setImageFiles([...copyArr]);
								updateImageFiles([...imageFiles]);
							}}>
							<IconTrash size={32} />
						</ActionIcon>
					</Tooltip>
				</Group>
				<Center>
					<Image
						height={120}
						src={imageUrl}
						styles={{
							root: {
								background: 'black',
							},
							imageWrapper: {
								'transition': '.5s ease',
								'&:hover': {
									opacity: 0.5,
								},
							},
						}}
					/>
				</Center>
			</Stack>
		);
	});

	return (
		<>
			<Dropzone
				onDrop={(files) => {
					setImageFiles((prevFiles) => [...prevFiles, ...files]);
					updateImageFiles([...imageFiles, ...files]);
				}}
				onReject={(files) => console.log('rejected files', files)}
				maxSize={3 * 1024 ** 2}
				accept={IMAGE_MIME_TYPE}>
				<Group
					position='center'
					spacing='xl'
					style={{ minHeight: 100, pointerEvents: 'none' }}>
					<Dropzone.Accept>
						<IconUpload
							size={50}
							stroke={1.5}
							color={
								theme.colors[theme.primaryColor][
									theme.colorScheme === 'dark' ? 4 : 6
								]
							}
						/>
					</Dropzone.Accept>
					<Dropzone.Reject>
						<IconX
							size={50}
							stroke={1.5}
						/>
					</Dropzone.Reject>
					<Dropzone.Idle>
						<IconPhoto
							size={50}
							stroke={1.5}
						/>
					</Dropzone.Idle>

					<div>
						<Text
							size='xl'
							inline>
							Drag images here or click to select files
						</Text>
						<Text
							size='sm'
							color='dimmed'
							inline
							mt={7}>
							Attach as many files as you like, each file should
							not exceed 5mb
						</Text>
					</div>
				</Group>
			</Dropzone>
			<SimpleGrid
				cols={6}
				breakpoints={[
					{ maxWidth: 755, cols: 5, spacing: 'sm' },
					{ maxWidth: 600, cols: 1, spacing: 'sm' },
				]}
				style={{ position: 'relative' }}>
				{previews}
			</SimpleGrid>
		</>
	);
}
