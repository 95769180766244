import ls from "localstorage-slim";
import encUTF8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

ls.config.encrypt = true; // global encryption
ls.config.secret = process.env.REACT_APP_SECRET;
// configure the encryption logic to store data to locastorage
ls.config.encrypter = (data, secret) =>
  AES.encrypt(JSON.stringify(data), secret).toString();

//   configure decryptor logic to show save data
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
  } catch (e) {
    return data;
  }
};

export const encrypt = (data, secret) => ls.set(data, secret); // encrypt the data to localstores

export const decrypt = (data, secret) => ls.get(data, secret); // decrypt the store data
