import { useContext, createContext } from 'react';
import { useLocalStorage } from '../utils/useLocalStorage';

const CartContext = createContext();

const CartProvider = ({ children }) => {
	const [cart, setCart] = useLocalStorage('cart', [{ _id: '', qty: 0 }]);

	const addToCart = (id, qty) => {
		setCart((currItems) => {
			if (currItems.find((item) => item._id === id) == null) {
				return [...currItems, { _id: id, qty }];
			} else {
				return currItems.map((item) => {
					if (item._id === id) {
						return { ...item, qty };
					} else {
						return item;
					}
				});
			}
		});
	};

	const removeFromCart = (id) => {
		setCart((currItems) => {
			if (cart.length === 0) {
				return [];
			} else {
				return currItems.filter((item) => item._id !== id);
			}
		});
	};

	return (
		<CartContext.Provider value={{ addToCart, removeFromCart, cart }}>
			{children}
		</CartContext.Provider>
	);
};

export default CartProvider;

export const useCartContext = () => {
	return useContext(CartContext);
};
