import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Context from '../context/context';
import { Collapse, createStyles, MediaQuery } from '@mantine/core';
import { useLocation } from 'react-router-dom';
// import { useMediaQuery } from '@mantine/hooks';

const dataList = [
	'top seller',
	'Mask',
	'health',
	'laptops',
	'ipad',
	'phones',
	'accessories',
	'parts',
	'all products',
];

const useStyle = createStyles({
	navbar: {
		'cursor': 'pointer',
		'position': 'relative',
		// bottom: '0px',
		'zIndex': 99,
		'backgroundColor': 'white',
		'maxWidth': '30rem',
		'marginRight': '1.5rem',

		'width': '100%',
		// 'paddingInline': '20px',
		'& h2': {
			backgroundColor: ' black',
			color: 'white',
			fontWeight: 900,
			textTransform: 'uppercase',
			fontSize: 18,
			padding: 10,
			letterSpacing: 0.5,
		},
	},
	ul: {
		'color': '#444444',
		'fontSize': 18,
		'fontWeight': 700,
		'listStyle': 'none',
		'padding': '5px 20px',
		'textTransform': 'capitalize',
		'border': '2.5px solid #ebebeb',
		'borderTop': 0,

		'li + li': {
			borderTop: '1px solid #e9e9e9',
		},
		'li': {
			'padding': '15px 0',
			'&:hover': { cursor: 'pointer' },
		},
	},
});
export const Category = () => {
	const { dispatch, RandomNumber } = useContext(Context);
	const [isOpen, setIsOpen] = useState(true);

	// const isOnSmallScreen = useMediaQuery('(max-width: 768px )');
	const location = useLocation();

	useEffect(() => {
		if (location.pathname !== '/') {
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	}, [location.pathname]);

	const { classes } = useStyle();

	const CategoryList = ({ list }) => (
		<ul className={classes.ul}>
			{list.map((categoryItems) => {
				return (
					<li
						key={categoryItems}
						onClick={() => filter(categoryItems)}>
						{categoryItems}
					</li>
				);
			})}
		</ul>
	);

	const filter = async (item) => {
		let timer = RandomNumber(800, 1500);
		dispatch({ type: 'ISLOADING', payload: true });

		item = item.toUpperCase();
		const returnedItem = await axios.get('/products/');

		let response = await returnedItem.data.doc;
		response.forEach((upper) => {
			upper.category = upper.category?.toUpperCase();
		});

		const filtered = await response.filter((filterItem) => {
			return filterItem.category === item;
		});
		setTimeout(() => {
			item === 'ALL PRODUCTS'
				? dispatch({
						type: 'PRODUCT_DATA',
						payload: response,
				  })
				: dispatch({
						type: 'PRODUCT_DATA',
						payload: filtered,
				  });
			dispatch({ type: 'ISLOADING', payload: false });
		}, timer);
	};
	return (
		<MediaQuery
			smallerThan='sm'
			styles={{ display: 'none' }}>
			<nav className={classes.navbar}>
				<h2
					onClick={() => setIsOpen(!isOpen)}
					onBlur={() => setIsOpen(!isOpen)}>
					shop by Categories
				</h2>
				<Collapse
					in={isOpen}
					transitionDuration={200}
					style={{
						position: 'absolute',
						zIndex: 9,
						backgroundColor: 'white',
						width: '100%',
					}}>
					<CategoryList list={dataList} />
				</Collapse>
			</nav>
		</MediaQuery>
	);
};
