import axios from 'axios';

export const getEditProduct = async (itemId) => {
	console.log(itemId.params.productId);
	try {
		// dispatch({ type: "ISLOADING", payload: true });
		return await axios
			.get(`/api/v1/admin/products/item/${itemId.params.productId}`, {
				cancelToken: SourceBuffer.token,
			})
			.then(({ data }) => {
				console.log(data.doc);
				return data.doc;
				// dispatch({ type: "ISLOADING", payload: false });
				// dispatch({ type: "ITEM_DETAIL", payload: data.data.doc });
			});
	} catch (error) {
		console.log(error);
	}
};

export const getSingleProduct = async ({ params }) => {
	try {
		const { data } = await axios.get(
			`/api/v1/product/${params.productId}`,
			{
				cancelToken: SourceBuffer.token,
			}
		);
		console.log(params);
		return data.doc;
	} catch (error) {
		console.log(error);
	}
};

export const getProducts = async () => {
	try {
		// dispatch({ type: "ISLOADING", payload: true });
		const products = await axios.get(`/api/v1/products`, {
			cancelToken: SourceBuffer.token,
		});
		return products.data.doc;
	} catch (error) {
		console.log(error);
	}
};

export const deteleProduct = async (params) => {
	const deletedProduct = await axios.delete(
		`/api/v1/product/${params.productId}/delete`
	);
	if (deletedProduct.data.statusCode === 200) {
		console.log(deletedProduct.data.message);
	} else console.log(deletedProduct.data.error);
};

export const userLogin = async (params) => {
	const user = await params;
	return user;
};
