import React from 'react';
import {
	Box,
	CloseButton,
	Divider,
	Group,
	Image,
	Stack,
	Text,
	createStyles,
} from '@mantine/core';
import { useCartContext } from '../../context/CartContext';
import { useProductsContext } from '../../context/ProductsContext';
import { format } from '../../utils/format';

const useStyle = createStyles({
	closeButton: {
		position: 'absolute',
		zIndex: 99,
		background: '#fff',
		color: '#000',
		right: '-10px',
		top: '-12px',
		boxShadow: '0px 0px 5px 2px  rgba(0,0,0,0.3)',
	},
});

const ShoppingCart = ({ _id, qty }) => {
	const { removeFromCart } = useCartContext();
	const { classes } = useStyle();
	const {
		value: { products },
	} = useProductsContext();

	return products
		.filter((item) => item._id === _id)
		.map((item) => (
			<React.Fragment key={item._id}>
				<Group
					py={10}
					align='flex-start'>
					<Stack
						spacing={0}
						align='start'>
						<Text
							weight={700}
							size='lg'>
							{item?.title}
						</Text>
						<Text
							size='lg'
							weight={500}
							color='#6b6b6b'
							span>
							{qty} &times; {format(item?.price?.sales)}
						</Text>
					</Stack>
					<Box
						style={{
							position: 'relative',
							marginLeft: 'auto',
						}}>
						<CloseButton
							size={24}
							radius='lg'
							p={3}
							title='Remove product from cart'
							className={classes.closeButton}
							onClick={() => removeFromCart(item._id)}
						/>

						<Image
							src={item?.images[0].originalURL}
							width={75}
							height={75}
						/>
					</Box>
				</Group>
				<Divider />
			</React.Fragment>
		));
};

export default ShoppingCart;
