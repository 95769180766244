// import React, { useContext } from 'react';
// import Context from '../context/context';

export const Footer = () => {
	// const {
	//   hideComponents: { footer },
	// } = useContext(Context);

	return (
		<footer>
			<div className='footer-section'>
				<div className='footer-content'>
					<div className='footer-text'>
						<h2>
							Ordernar a traves del email: orders@xomos1.com{' '}
							<br />
							mesajes de pedidos al: 929-346-1388 contacto: Juan
							Xomos
						</h2>
					</div>
					{/* </div> */}
				</div>
			</div>
		</footer>
	);
};
