import '../assets/css/admin.css';
import {
	Table,
	Group,
	Text,
	HoverCard,
	Center,
	MediaQuery,
} from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';

import { Loader } from './loader';

import { Link, Outlet } from 'react-router-dom';
// import { getProducts } from '../utils/fetchProducts';
import { IconCheck, IconDots, IconX } from '@tabler/icons';
import { useProductsContext } from '../context/ProductsContext';
import { useEffect } from 'react';
export default function Admin() {
	// eslint-disable-next-line
	// const adminProducts = useLoaderData();
	const { deleteProduct, getProducts, value } = useProductsContext();

	useEffect(() => {
		getProducts();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (value.responseMsg) {
			setTimeout(() => {
				updateNotification({
					id: 1,
					title: 'Update Notification Title',
					message: value.responseMsg,
					icon: <IconX size={16} />,
					color: 'red',
				});
			}, 800);
		} else {
			setTimeout(() => {
				updateNotification({
					id: 1,
					title: 'Update Notification Title',
					message: value.responseMsg,
					icon: <IconCheck size={16} />,
					// autoClose: 500,
					color: 'red',
				});
			}, 800);
		}
	}, [value]);

	const ActionMenu = ({ product }) => {
		return (
			<Group position='center'>
				<HoverCard
					width={118}
					shadow='md'>
					<HoverCard.Target>
						<Text style={{ cursor: 'pointer' }}>
							<IconDots />
						</Text>
					</HoverCard.Target>
					<HoverCard.Dropdown>
						<Group>
							<Link
								to={`/admin/product/${product._id}/edit`}
								state={{ openModal: true, product: product }}>
								Edit
							</Link>
							<Text
								style={{ cursor: 'pointer' }}
								size='sm'
								onClick={async () => {
									showNotification({
										loading: true,
										id: 1,
										title: 'Deleting item...',
										message:
											'Hey there, your code is awesome! 🤥',
										autoClose: false,
										color: 'red',
									});
									await deleteProduct(product._id);
								}}>
								Delete
							</Text>
						</Group>
					</HoverCard.Dropdown>
				</HoverCard>
			</Group>
		);
	};

	const rows = value.products.map((element) => (
		<tr
			key={element._id}
			style={{ fontSize: '20px' }}>
			<MediaQuery
				smallerThan='sm'
				styles={{ display: 'none' }}>
				<td style={{ width: '140px', height: '75px' }}>
					<img
						src={element?.images[0]?.originalURL}
						width='100px'
						alt='product'
					/>
				</td>
			</MediaQuery>
			<td style={{ fontSize: '20px' }}>{element.title}</td>
			<MediaQuery
				smallerThan='sm'
				styles={{ display: 'none' }}>
				<td
					style={{
						textAlign: 'end',
						width: '75px',
						fontSize: '20px',
					}}>
					{!element.price?.retail ? '' : `$${element.price?.retail}`}
				</td>
			</MediaQuery>
			<MediaQuery
				smallerThan='sm'
				styles={{ display: 'none' }}>
				<td
					style={{
						textAlign: 'end',
						width: '125px',
						fontSize: '20px',
					}}>
					{!element.price?.sales ? 0 : `$${element.price?.sales}`}
				</td>
			</MediaQuery>
			<MediaQuery
				smallerThan='sm'
				styles={{ display: 'none' }}>
				<td style={{ textAlign: 'center', fontSize: '20px' }}>
					{element.stockQty}
				</td>
			</MediaQuery>
			<td>
				<ActionMenu product={element} />
			</td>
		</tr>
	));

	return (
		<>
			<Loader />
			<Center style={{ width: '100%' }}>
				<Table
					striped
					highlightOnHover
					horizontalSpacing='lg'>
					<thead>
						<tr>
							<MediaQuery
								smallerThan='sm'
								styles={{ display: 'none' }}>
								<th></th>
							</MediaQuery>
							<th>Product</th>
							<MediaQuery
								smallerThan='sm'
								styles={{ display: 'none' }}>
								<th style={{ textAlign: 'end' }}>Price</th>
							</MediaQuery>
							<MediaQuery
								smallerThan='sm'
								styles={{ display: 'none' }}>
								<th style={{ textAlign: 'end' }}>
									Listing Price
								</th>
							</MediaQuery>
							<MediaQuery
								smallerThan='sm'
								styles={{ display: 'none' }}>
								<th style={{ textAlign: 'center' }}>Stock</th>
							</MediaQuery>
							<th style={{ textAlign: 'center' }}>Action</th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</Center>
			<Outlet />
		</>
	);
}

export async function productList() {
	// const adminProducts = await getProducts();
	// const adminProducts = await getProducts();
	// return adminProducts;
}
