import React, { useContext, useEffect } from 'react';
import Context from '../context/context';

import '../assets/css/spinner.css';

export const Loader = ({ isLoading }) => {
	let { loading } = useContext(Context);
	// const windowOffset = 0;

	useEffect(() => {
		loading ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
	}, [loading]);
	return (
		<>
			{(loading || isLoading) && (
				<div className='spinner-container'>
					<div className='spinner'>
						<div className='bounce1'></div>
						<div className='bounce2'></div>
						<div className='bounce3'></div>
					</div>
				</div>
			)}
		</>
	);
};

//
