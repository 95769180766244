import {
	createBrowserRouter,
	createRoutesFromElements,
	Link,
	Route,
} from 'react-router-dom';
import AdminDashboard from '../Components/adminDashboard';
import EditModal from '../Components/EditModal/EditModal';
import App from '../App';
import { productList } from '../Components/Admin';
import {
	ProductDetails,
	singleProductDetail,
} from '../Components/productDetails';
import { ProductCheckout } from '../Components/productCheckout';
import LoginPage from '../Components/Login/Login';
import ProtectedRoute from '../Components/Login/ProtectedRoute';
import { UserLoggedIn } from '../context/UserLogin';
import { ProductsProvider } from '../context/ProductsContext';
import AddNewProduct from '../Components/AddNewProduct/AddNewProduct';
import { HiHome } from 'react-icons/hi';
import CartProvider from '../context/CartContext';

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route
				path='/'
				handle={ { crumb: ()=> <Link to="/"><HiHome/></Link> } }
				element={
					<ProductsProvider>
						<CartProvider>
							
						<App />
</CartProvider>
					</ProductsProvider>
				}>
					
				<Route path='/shop'handle={{crumb: ()=><Link to="/">Shop</Link>}} > 
					<Route
						handle={{crumb: (data)=><span>{data.title}</span>}}
						path=':productId/'
						loader={singleProductDetail}
						element={<ProductDetails />}
					/>
					<Route
						id={4}
						title='Checkout'
						path='checkout'
						loader={singleProductDetail}
						element={<ProductCheckout />}
					/>
 				</Route>

			</Route>

			<Route
				path='/admin'
				element={
					<UserLoggedIn>
						<ProtectedRoute />
					</UserLoggedIn>
				}>
				<Route
					path='products'
					loader={productList}
					element={
						<ProductsProvider>
							<AdminDashboard />
						</ProductsProvider>
					}
					children={
						<Route
							path='new'
							element={<AddNewProduct />}
						/>
					}
				/>
				<Route
					path='product'
					loader={productList}
					element={
						<ProductsProvider>
							<AdminDashboard />
						</ProductsProvider>
					}>
					<Route
						path=':productId/edit'
						// loader={singleProduct}
						element={<EditModal />}
					/>
				</Route>
			</Route>
			<Route
				path='login'
				element={
					<UserLoggedIn>
						<LoginPage />
					</UserLoggedIn>
				}
			/>
		</Route>
	)
);
