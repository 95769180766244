import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './utils/router';
import './assets/css/main.css';
import './assets/css/dialog.css';

import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

window.addEventListener('beforeunload', () =>
	localStorage.removeItem('isUserAuthenticated')
);
const container = document.getElementById('root');
createRoot(container).render(
	<MantineProvider
		theme={{
			backgroundColor: '#fff',
			fontFamily: "Figtree, 'Noto Sans', sands-serif",
			headings: {
				fontFamily: "Figtree, 'Noto Sans', sands-serif !important",
			},
			components: {
				TextInput: {
					styles: {
						input: {
							fontFamily:
								"Figtree, 'Noto Sans', sands-serif !important",
						},
						label: {
							// background: "red",
							fontFamily:
								"Figtree, 'Noto Sans', sands-serif !important",
						},
					},
				},
			},
		}}
		withGlobalStyles
		withNormalizeCSS>
		
		<NotificationsProvider>
			<RouterProvider router={router} />
		</NotificationsProvider>
	</MantineProvider>
);
